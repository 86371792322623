import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import { useAppContext } from "../../context/AppContext";

const mapContainerStyle = {
  height: "800px",
};

const center = {
  lat: 31.9589277, // You can use the coordinates of one of the schools as the center
  lng: 35.8666995,
};

const MapComponent = ({ data, image, loading = false }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthContext();
  const { language } = useAppContext();

  return (
    <div className="map-view-item">
      <LoadScript googleMapsApiKey="AIzaSyDQ2DCe8qS4qVCkMtRZRnZZ_TF2qq1HSvs">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={15}
        >
          {loading ? (
            <Loader />
          ) : (
            data?.map((item, index) => {
              console.log(
                item,
                item?.latitude,
                "item?.latitude",
                item?.longitude,
                "item?.longitude"
              );
              return (
                <Marker
                  key={index}
                  position={{
                    lat: item?.latitude,
                    lng: item?.longitude,
                  }}
                  title={
                    language !== "en" && language !== null
                      ? item?.[`${language}_name`] ?? item?.name
                      : item?.name
                  }
                  //               icon={{
                  //                 url:
                  //                   "data:image/svg+xml;charset=UTF-8," +
                  //                   encodeURIComponent(`
                  //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40" height="40">
                  //     <path fill="##021C3F" d="M16 2C9.373 2 4 7.373 4 14c0 6.627 12 16 12 16s12-9.373 12-16c0-6.627-5.373-12-12-12zM16 18.5c-2.485 0-4.5-2.015-4.5-4.5s2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5z"/>
                  //   </svg>
                  // `),
                  //                 scaledSize: new window.google.maps.Size(40, 40), // Size of marker
                  //               }}
                  onClick={() => navigate(`/provider/${item?._id}`)}
                />
              );
            })
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;
