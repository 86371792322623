import {
  Select,
  Form,
  Row,
  Col,
  Rate,
  Image,
  Drawer,
  Space,
  Slider,
} from "antd";

import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Collapse } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ShortingImg from "../../assets/images/shortimg.png";
import FilterbuttonImg from "../../assets/images/filterbutton.png";
import InviteFriends from "../../components/InviteFriends";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import prepImgNew123 from "../../assets/images/Preparations-maps2.png";
import NoService from "../../assets/images/noservice.png";
import notfound from "../../assets/images/weeding.png";
import Loader from "../../components/Loader";
import lang from "../../helper/langHelper";
import { UndoOutlined } from "@ant-design/icons";
import { useAppContext } from "../../context/AppContext";
import Commonfunction from "../../helper/CommonFunction";
import NoData from "../../assets/images/no-data.png";
import ManageAttributeFilter from "../../helper/ManageAttributeFilter";
import ProviderLocationMap from "./ProviderLocationMap";
function AllProvider() {
  const { language } = useAppContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { request } = useRequest();
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [events, setEvents] = useState([]);
  const [cities, setCities] = useState([]);
  const [filterServices, setFilterServices] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [attrFilterData, setAttrFilterData] = useState({});
  const [viewTypeData, setViewTypeData] = useState("listView");
  const CommonFunction = Commonfunction();

  const urlParams = new URLSearchParams(window.location.search);
  const cityId = urlParams.get("city_id");
  const categoryId = urlParams.get("category_id");
  const subCategoryId = urlParams.get("sub_category_id");
  const serviceId = urlParams.get("service_id");
  const eventTypeId = urlParams.get("eventtype_id");
  const sort = urlParams.get("sort");
  const rate = urlParams.get("rate");
  const keyFilter = urlParams.get("key_filter");
  const price = urlParams.get("price");
  const minPrice = urlParams.get("min_price");
  const maxPrice = urlParams.get("max_price");

  const [filter, setFilter] = useState({
    city_id: cityId ?? undefined,
    category_id: categoryId ?? undefined,
    sub_category_id: subCategoryId ?? undefined,
    service_id: serviceId ?? undefined,
    eventtype_id: eventTypeId ?? undefined,
    sort: sort ?? undefined,
    rate: rate ?? undefined,
    key_filter: keyFilter ?? "all",
    price: price ?? undefined,
    min_price: minPrice ?? undefined,
    max_price: maxPrice ?? undefined,
  });
  const params = useParams();

  const getProviders = () => {
    setLoading(true);
    const payload = {};
    payload.attribute_ids = attrFilterData ? attrFilterData : "";
    console.log(payload, "payload");

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v != undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    if (queryString) {
      queryString.split("&").forEach((param) => {
        const [key, value] = param.split("=");
        urlParams.set(key, value);
      });
    }
    navigate(`/all-providers${queryString ? `?${queryString}` : ""}`);

    request({
      url: `${apiPath.getProvider}?search=${""}${
        queryString ? `&${queryString}` : ""
      }`,
      method: "get",
      data: payload,
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setProviders(data);
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const getFilter = () => {
    request({
      url: `${apiPath.getProvider}/filters`,
      method: "GET",
      onSuccess: (data) => {
        setCategories(data?.categories);
        setCities(data?.cities);
        // setServices(data?.services);
        setEvents(data?.eventTypes);
      },
    });
  };

  const getSubCategories = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.subCategories}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) setSubCategories(data?.docs);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getProviders();
    getFilter();
  }, [filter, attrFilterData]);

  useEffect(() => {
    if (!filter?.category_id) return;
    getFilterService(filter?.category_id);
  }, [filter?.category_id]);

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [placement, setPlacement] = useState("left");

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const handleNavProvider = (id) => {
    let vendor_id = id ? id : "";
    CommonFunction.updateProviderViewCount(vendor_id);
  };

  const getFilterService = (id) => {
    setLoading(true);
    request({
      url: apiPath.common.services + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        if (status) {
          setServices(data?.data ?? []);
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const getFilterAttributes = (id) => {
    setLoading(true);

    request({
      url:
        apiPath.common.filteredAttribute + "/" + filter.category_id + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        if (status) {
          setAttributes(data.data);
        }
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  const resetFilter = () => {
    setFilter({
      city_id: undefined,
      category_id: undefined,
      sub_category_id: undefined,
      service_id: undefined,
      sort: undefined,
      rate: undefined,
      key_filter: "all",
      price: undefined,
      min_price: undefined,
      max_price: undefined,
    });

    setServices([]);
    setAttributes([]);
    setAttrFilterData([]);
  };

  const handleCategoryOnChange = (value) => {
    onChange("category_id", value);
    getFilterService(value);
    setFilter((prev) => ({
      ...prev,
      service_id: undefined,
    }));

    setServices([]);
    setAttributes([]);
    setAttrFilterData([]);
  };

  const handlePriceRangeChange = (value) => {
    const timeout = setTimeout(() => {
      setFilter((prevFilter) => ({
        ...prevFilter,
        min_price: value[0],
        max_price: value[1],
      }));
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  };

  return (
    <Main>
      <>
        <section className="filter-bg">
          <div className="container">
            <Row gutter={24}>
              <Col span={24} md={6} lg={6} className="d-none d-lg-block">
                <div className="panel-group">
                  <div className="panel panel-default main-filter-btn-main-145">
                    <div className="panel-heading main-filter-btn-main-45">
                      <Button
                        onClick={() => setOpen(!open)}
                        // aria-controls="filter-collapse"
                        // aria-expanded={open}
                        className="float-end filter-button-no-reset"
                      >
                        <div>
                          <img src={FilterbuttonImg} alt="Filter button" />
                          <span>{lang("Filter")}</span>
                        </div>
                      </Button>
                      <Button
                        onClick={() => {
                          resetFilter();
                        }}
                        type="primary"
                        className="filter-button-reset"
                      >
                        <div>
                          <UndoOutlined />
                          <span>{lang("Reset")}</span>
                        </div>
                      </Button>
                    </div>

                    <div className="baccolor">
                      <div className="first-bg">
                        <div className="manager">
                          <h6>{lang("City")}</h6>

                          <Form.Item className="filtter-select">
                            <Select
                              className="form-select"
                              last20Years
                              width="110px"
                              placeholder={lang("Select City")}
                              showSearch
                              value={filter.city_id}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={cities?.map((item) => ({
                                value: item._id,
                                label: item.name,
                              }))}
                              onChange={(value) => onChange("city_id", value)}
                            />
                          </Form.Item>
                        </div>
                        <div className="manager">
                          <h6>{lang("Event Type")}</h6>

                          <Form.Item className="filtter-select">
                            <Select
                              className="form-select"
                              aria-label="Default select example"
                              placeholder={lang("Select Event")}
                              value={filter.eventtype_id}
                              onChange={(value) =>
                                onChange("eventtype_id", value)
                              }
                            >
                              {events?.length &&
                                events?.map((item) => (
                                  <Select.Option
                                    value={item._id}
                                    key={item._id}
                                  >
                                    {language !== "en" && language !== null
                                      ? item?.[`${language}_name`] ?? item?.name
                                      : item?.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="manager">
                          <h6>{lang("Category")}</h6>

                          <Form.Item className="filtter-select">
                            <Select
                              className="form-select"
                              last20Years
                              width="110px"
                              placeholder={lang("Select Category")}
                              showSearch
                              value={filter.category_id}
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              options={categories?.map((item) => ({
                                value: item._id,
                                label:
                                  language !== "en" && language !== null
                                    ? item?.[`${language}_name`] ?? item?.name
                                    : item?.name,
                              }))}
                              onChange={(value) => {
                                handleCategoryOnChange(value);
                              }}
                            />
                          </Form.Item>
                        </div>

                        <div className="manager">
                          <h6>{lang("Services")}</h6>

                          <Form.Item className="filtter-select">
                            <Select
                              className="form-select"
                              aria-label="Default select example"
                              placeholder={lang("Select Services")}
                              value={filter.service_id}
                              onChange={(value) => {
                                onChange("service_id", value);
                                getFilterAttributes(value);
                              }}
                            >
                              {services?.length &&
                                services?.map((item) => (
                                  <Select.Option
                                    value={item._id}
                                    key={item._id}
                                  >
                                    {language !== "en" && language !== null
                                      ? item?.[`${language}_name`] ?? item?.name
                                      : item?.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="manager">
                          <h6>{lang("Price")}</h6>
                          <div>
                            <div className="range-input">
                              <Slider
                                range
                                min={0}
                                max={10000}
                                step={100}
                                defaultValue={[
                                  filter.min_price,
                                  filter.max_price,
                                ]}
                                value={[filter.min_price, filter.max_price]}
                                onChange={handlePriceRangeChange}
                              />
                            </div>
                            {/* Display current range values */}
                            <div style={{ marginTop: 20 }}>
                              {lang("Selected Range:")} {filter.min_price} -{" "}
                              {filter.max_price}
                            </div>
                          </div>
                        </div>

                        <div className="manager">
                          <h6>
                            <img src="image/shortimg.png" alt="" />{" "}
                            {lang("Sort")}{" "}
                          </h6>
                          <div className="radio-container-maain">
                            <label className="radio-container">
                              <input
                                checked={filter.sort === "A_Z"}
                                type="radio"
                                name="radio-option"
                                onChange={(value) => onChange("sort", "A_Z")}
                              />
                              <span className="radio-checkmark" />
                              <img
                                src="image/bi_sort-alpha-up.png"
                                alt=""
                              />{" "}
                              {lang("A to Z")}
                            </label>
                            <label className="radio-container">
                              <input
                                checked={filter.sort === "Z_A"}
                                type="radio"
                                name="radio-option"
                                onChange={(value) => onChange("sort", "Z_A")}
                              />
                              <span className="radio-checkmark" />
                              <img
                                src="image/bi_sort-alpha-down.png"
                                alt=""
                              />{" "}
                              {lang("Z to A")}
                            </label>
                            <label className="radio-container">
                              <input
                                checked={filter.sort === "most_view"}
                                type="radio"
                                name="radio-option"
                                onChange={(value) =>
                                  onChange("sort", "most_view")
                                }
                              />
                              <span className="radio-checkmark" />
                              <img
                                src="image/bi_sort-alpha-down.png"
                                alt=""
                              />{" "}
                              {lang("Most View Provider")}
                            </label>
                          </div>
                        </div>

                        <div className="manager">
                          <h6>{lang("Rating")} </h6>
                          <div className="distance-radio-con">
                            <label className="radio-container">
                              <input
                                type="radio"
                                name="radio-option"
                                checked={filter.sort === "Rate_L_to_H"}
                                onChange={(value) =>
                                  onChange("sort", "Rate_L_to_H")
                                }
                              />
                              <span className="radio-checkmark" />
                              <img src="image/pricedown.png" alt="" />{" "}
                              {lang("Low to High")}
                            </label>
                            <label className="radio-container">
                              <input
                                type="radio"
                                name="radio-option"
                                checked={filter.sort === "Rate_H_to_L"}
                                onChange={(value) =>
                                  onChange("sort", "Rate_H_to_L")
                                }
                              />
                              <span className="radio-checkmark" />
                              <img src="image/pricelow.png" alt="" />{" "}
                              {lang("High to Low")}
                            </label>
                          </div>
                        </div>
                        <div className="manager">
                          <h6>{lang("Distance")} </h6>
                          <div className="distance-radio-con">
                            <label className="radio-container">
                              <input
                                type="radio"
                                name="radio-option"
                                checked={filter.sort === "near"}
                                onChange={(value) => onChange("sort", "near")}
                              />
                              <span className="radio-checkmark" />
                              <img src="image/pricedown.png" alt="" />
                              <img src="image/pricedown.png" alt="" />
                              {lang("Nearest (Near By)")}
                            </label>
                            <label className="radio-container">
                              <input
                                type="radio"
                                name="radio-option"
                                checked={filter.sort === "far"}
                                onChange={(value) => onChange("sort", "far")}
                              />
                              <span className="radio-checkmark" />
                              <img src="image/pricelow.png" alt="" />
                              <img src="image/pricelow.png" alt="" />
                              {lang("Farthest (Far Away)")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* </Collapse> */}
                  </div>
                </div>
              </Col>

              <Col span={24} md={24} lg={18}>
                <div className="result-and-shot-div">
                  <div className="for-filterbutton-drowerss d-lg-none d-md-block">
                    <Button type="primary" onClick={showDrawer}>
                      <img src={FilterbuttonImg} alt="Filter button" />
                    </Button>

                    <Drawer
                      title={lang("Filter")}
                      placement={placement}
                      width={350}
                      onClose={onClose}
                      open={isDrawerOpen}
                      extra={
                        <Space>
                          <Button
                            onClick={() => {
                              setFilter({
                                category_id: params.id ? params.id : undefined,
                                sub_category_id: undefined,
                                service_id: undefined,
                                sort: undefined,
                                rate: undefined,
                                key_filter: "all",
                              });
                            }}
                            type="primary"
                            className="filter-button-reset filter-button-reset-44"
                          >
                            <div>
                              <UndoOutlined />
                              <span>{lang("Reset")}</span>
                            </div>
                          </Button>
                        </Space>
                      }
                    >
                      <div className=" filter-bg ">
                        <div className="panel-group">
                          <div className="panel panel-default main-filter-btn-main-145 main-filter-btn-main-146 ">
                            <div>
                              <div className="baccolor">
                                <div className="first-bg">
                                  <div className="manager">
                                    <h6>{lang("City")}</h6>

                                    <Form.Item className="filtter-select">
                                      <Select
                                        className="form-select"
                                        last20Years
                                        width="110px"
                                        placeholder={lang("Select City")}
                                        showSearch
                                        value={filter.city_id}
                                        filterOption={(input, option) =>
                                          option.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={cities?.map((item) => ({
                                          value: item._id,
                                          label: item.name,
                                        }))}
                                        onChange={(value) =>
                                          onChange("city_id", value)
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="manager">
                                    <h6>{lang("Category")}</h6>

                                    <Form.Item className="filtter-select">
                                      <Select
                                        className="form-select"
                                        last20Years
                                        width="110px"
                                        placeholder={lang("Select Category")}
                                        showSearch
                                        value={filter.category_id}
                                        filterOption={(input, option) =>
                                          option.label
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={categories?.map((item) => ({
                                          value: item._id,
                                          label:
                                            language !== "en" &&
                                            language !== null
                                              ? item?.[`${language}_name`] ??
                                                item?.name
                                              : item?.name,
                                        }))}
                                        onChange={(value) => {
                                          onChange("category_id", value);
                                          getSubCategories(value);
                                        }}
                                      />
                                    </Form.Item>
                                  </div>

                                  <div className="manager">
                                    <h6>{lang("Services")}</h6>

                                    <Form.Item className="filtter-select">
                                      <Select
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder={lang("Select Services")}
                                        onChange={(value) =>
                                          onChange("service_id", value)
                                        }
                                      >
                                        {services?.length &&
                                          services?.map((item) => (
                                            <Select.Option
                                              value={item._id}
                                              key={item._id}
                                            >
                                              {language !== "en" &&
                                              language !== null
                                                ? item?.[`${language}_name`] ??
                                                  item?.name
                                                : item?.name}
                                            </Select.Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                  <div className="manager">
                                    <h6>{lang("Event Type")}</h6>

                                    <Form.Item className="filtter-select">
                                      <Select
                                        className="form-select"
                                        aria-label="Default select example"
                                        placeholder={lang("Select Event")}
                                        onChange={(value) =>
                                          onChange("eventtype_id", value)
                                        }
                                      >
                                        {events?.length &&
                                          events?.map((item) => (
                                            <Select.Option
                                              value={item._id}
                                              key={item._id}
                                            >
                                              {language !== "en" &&
                                              language !== null
                                                ? item?.[`${language}_name`] ??
                                                  item?.name
                                                : item?.name}
                                            </Select.Option>
                                          ))}
                                      </Select>
                                    </Form.Item>
                                  </div>

                                  <div className="manager">
                                    <h6>
                                      <img src="image/shortimg.png" alt="" />{" "}
                                      {lang("Sort")}{" "}
                                    </h6>
                                    <div className="radio-container-maain">
                                      <label className="radio-container">
                                        <input
                                          checked={filter.sort === "A_Z"}
                                          type="radio"
                                          name="radio-option"
                                          onChange={(value) =>
                                            onChange("sort", "A_Z")
                                          }
                                        />
                                        <span className="radio-checkmark" />
                                        <img
                                          src="image/bi_sort-alpha-up.png"
                                          alt=""
                                        />{" "}
                                        {lang("A to Z")}
                                      </label>
                                      <label className="radio-container">
                                        <input
                                          checked={filter.sort === "Z_A"}
                                          type="radio"
                                          name="radio-option"
                                          onChange={(value) =>
                                            onChange("sort", "Z_A")
                                          }
                                        />
                                        <span className="radio-checkmark" />
                                        <img
                                          src="image/bi_sort-alpha-down.png"
                                          alt=""
                                        />{" "}
                                        {lang("Z to A")}
                                      </label>
                                      <label className="radio-container">
                                        <input
                                          checked={filter.sort === "most_view"}
                                          type="radio"
                                          name="radio-option"
                                          onChange={(value) =>
                                            onChange("sort", "most_view")
                                          }
                                        />
                                        <span className="radio-checkmark" />
                                        <img
                                          src="image/bi_sort-alpha-down.png"
                                          alt=""
                                        />{" "}
                                        {lang("Most View Provider")}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="manager">
                                    <h6>{lang("Rating")} </h6>
                                    <div className="distance-radio-con">
                                      <label className="radio-container">
                                        <input
                                          type="radio"
                                          name="radio-option"
                                          checked={
                                            filter.sort === "Rate_L_to_H"
                                          }
                                          onChange={(value) =>
                                            onChange("sort", "Rate_L_to_H")
                                          }
                                        />
                                        <span className="radio-checkmark" />
                                        <img
                                          src="image/pricedown.png"
                                          alt=""
                                        />{" "}
                                        {lang("Low to High")}
                                      </label>
                                      <label className="radio-container">
                                        <input
                                          type="radio"
                                          name="radio-option"
                                          checked={
                                            filter.sort === "Rate_H_to_L"
                                          }
                                          onChange={(value) =>
                                            onChange("sort", "Rate_H_to_L")
                                          }
                                        />
                                        <span className="radio-checkmark" />
                                        <img
                                          src="image/pricelow.png"
                                          alt=""
                                        />{" "}
                                        {lang("High to Low")}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="manager">
                                    <h6>{lang("Distance")} </h6>
                                    <div className="distance-radio-con">
                                      <label className="radio-container">
                                        <input
                                          type="radio"
                                          name="radio-option"
                                        />
                                        <span className="radio-checkmark" />
                                        <img
                                          src="image/pricedown.png"
                                          alt=""
                                        />{" "}
                                        {lang("Near to Far")}
                                      </label>
                                      <label className="radio-container">
                                        <input
                                          type="radio"
                                          name="radio-option"
                                        />
                                        <span className="radio-checkmark" />
                                        <img
                                          src="image/pricelow.png"
                                          alt=""
                                        />{" "}
                                        {lang("Far to Near")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Drawer>
                  </div>

                  <div className="short-by-div">
                    <div className="result-headding">
                      <h4>{lang("Providers")}</h4>
                    </div>
                    <div className="main-new-select-lost-view">
                      <Select
                        defaultValue={"all"}
                        value={filter.key_filter}
                        className="button-firsttt"
                        options={[
                          {
                            value: "all",
                            label: lang("All"),
                          },
                          {
                            value: "featured",
                            label: lang("Featured"),
                          },
                        ]}
                        onChange={(value) => onChange("key_filter", value)}
                      />
                      <Select
                        defaultValue={"listView"}
                        value={viewTypeData}
                        className="button-firsttt"
                        options={[
                          {
                            value: "listView",
                            label: lang("List View"),
                          },
                          {
                            value: "mapView",
                            label: lang("Map View"),
                          },
                        ]}
                        onChange={(value) => setViewTypeData(value)}
                      />
                    </div>
                  </div>
                </div>

                {loading ? (
                  <Loader />
                ) : providers?.length && providers?.length > 0 ? (
                  <div className="service-main pt-0">
                    {viewTypeData === "mapView" ? (
                      <Col span={24} lg={24} className="main-google-map-class">
                        <Form.Item name="location">
                          <ProviderLocationMap
                            className="mt-3"
                            data={providers?.length ? providers : []}
                            loading={loading}
                            image={prepImgNew123}
                          />
                        </Form.Item>
                      </Col>
                    ) : (
                      <div className="service_img_main">
                        {providers?.map((item) => (
                          <div className="service-img" key={item._id}>
                            <div onClick={() => handleNavProvider(item?._id)}>
                              {item.is_featured && (
                                <div className="featured-type">
                                  {" "}
                                  <h3>{lang("Featured")}</h3>
                                </div>
                              )}
                              <div className="product-img">
                                <img
                                  src={
                                    item?.logo
                                      ? item.logo
                                      : item?.selected_category_id?.image
                                      ? item.selected_category_id.image
                                      : notfound
                                  }
                                />
                              </div>
                              <div className="service_text">
                                <h2>
                                  {language !== "en" && language !== null
                                    ? item?.[`${language}_name`] ?? item?.name
                                    : item?.name}
                                </h2>
                                <span>
                                  {language !== "en" && language !== null
                                    ? item?.[`${language}_description`] ??
                                      item?.description
                                    : item?.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="item">
                    <div className="wedding-main">
                      <div className="nosub-ccc">
                        <img src={NoData} alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </section>
        {/* <InviteFriends /> */}
      </>
    </Main>
  );
}

export default AllProvider;
