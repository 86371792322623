import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { getAuth, signInWithPopup,getRedirectResult, FacebookAuthProvider } from "firebase/auth";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";
import { ShowToast, Severty } from "../../helper/toast";
import FacebookImg from "../../assets/images/facebookk.png";
import { showError } from "../../helper/functions";
import lang from "../../helper/langHelper";

const FacebookLogin = () => {
  const provider = new FacebookAuthProvider();
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const location = window.location.pathname;
  const navigate = useNavigate();
  const auth = getAuth();

  const { request } = useRequest();

  const handleFacebookSignIn = () => {
 

    // Add permissions if needed
    provider.addScope('email');
    provider.addScope('public_profile');

    setLoading(true);

    signInWithPopup(auth, provider)
      .then((res) => {
        const { user } = res;
         console.log(user,"user------")
        const payload = {
          name: user.providerData[0].displayName,
          email: user.providerData[0].email,
          social_id: user.providerData[0].uid,
          image: user.providerData[0].photoURL,
          login_type : "Facebook"
        };
        console.log(payload,"payload")

        // if (navigator.geolocation) {
        //   navigator.geolocation.getCurrentPosition((position) => {
        //     payload.latitude = position.coords.latitude;
        //     payload.longitude = position.coords.longitude;
        //   }, showError);
        // } else {
        //   ShowToast("Geolocation is not supported by this browser.", Severty.ERROR);
        // }

        request({
          url: "app/auth/social-signup",
          method: "POST",
          data: payload,
          onSuccess: (data) => {
            setLoading(false);
            if (data.status) {
              setIsLoggedIn(true);
              setUserProfile(data.user);
              ShowToast(data.message, Severty.SUCCESS);
              localStorage.setItem("token", data.token);
              localStorage.setItem("userProfile", JSON.stringify(data.user));
              if(location === "/" || location === "/login" || location === "/signup") 
                setTimeout(() => navigate("/home"), 200);
            } else {
              ShowToast(data.message, Severty.ERROR);
            }
          },
          onError: (error) => {
            setLoading(false);
            ShowToast(error?.response?.data?.message, Severty.ERROR);
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error,"------error------")
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(
          `Error signing in with Facebook: ${errorCode} - ${errorMessage}`
        );
        // ShowToast("Facebook login failed", Severty.ERROR);
      });
  };

  return (
    <div
      onClick={handleFacebookSignIn}
      disabled={loading}
      // className="google-button-img"
      style={{cursor : "pointer"}}

    >

      {loading ? (
        <>
          <img width={35} src={FacebookImg} alt="Facebook" />
          {/* {" Logging in..."} */}
        </>
      ) : (
        <>
          <img className="google-button-img" width={35} src={FacebookImg} alt="Facebook" />
          {/* {lang("Login with Facebook")} */}
        </>
      )}
 
    </div>
  );
};

export default FacebookLogin;
